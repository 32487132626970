import { Component, ContentChild, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Method } from '../../models/Method';
import { MethodPipe } from '../../utils/method.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { IstdService } from '../../services/istd.service';
import { IstdEditComponent } from '../istd-edit/istd-edit.component';
import { FileImportDialogComponent } from '../file-import-dialog/file-import-dialog.component';
import { TargetType } from '../../models/TargetType';
import { LoadingService } from '../../services/loading.service';
import { Istd } from '../../models/Istd';
import { IonMode } from '../../models/IonMode';

@Component({
  selector: 'app-methods',
  templateUrl: './methods.component.html',
  styleUrls: ['./methods.component.css'],
})
export class MethodsComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  loading$ = this.loader.loading$;

  @ContentChild(TemplateRef) tplMethodList: TemplateRef<{ methods: Method[], source: string }>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mp: MethodPipe,
    public methodDialog: MatDialog,
    private istdService: IstdService,
    private fileImportDialog: MatDialog,
    private loader: LoadingService,
  ) {
  }

  private dbMethodsSubject$: BehaviorSubject<Method[]> = new BehaviorSubject<Method[]>([]);
  dbmethods$: Observable<Method[]> = this.dbMethodsSubject$.asObservable();

  private fileMethodsSubject$: BehaviorSubject<Method[]> = new BehaviorSubject<Method[]>([]);
  filemethods$: Observable<Method[]> = this.fileMethodsSubject$.asObservable();

  ngOnInit(): void {
    const fm = localStorage.getItem('fileMethods');
    if (!fm) {
      localStorage.setItem('fileMethods', '[]');
    } else {
      this.fileMethodsSubject$.next(JSON.parse(localStorage.getItem('fileMethods')));
    }

    this.istdService.methods$
    .pipe(
      tap(data => this.dbMethodsSubject$.next(this.typify(data))),
    ).subscribe();

    this.filemethods$.pipe(
      tap(x => console.log(`File methods changed\n${JSON.stringify(x)}`)),
    ).subscribe();
  }

  private typify(data: Method[]): Method[] {
    data.sort((a, b) => (this.mp.transform(a) < this.mp.transform(b) ?
      -1 : this.mp.transform(a) > this.mp.transform(b) ? 1 : 0));

    return data.map(x => {
      const types: {
        type: TargetType,
        count: number
      }[] = [...new Set(x.targets.map(t => t.target_type as TargetType))]
      .map(type => ({type, count: x.targets.filter(t => t.target_type === type).length}))
      .sort((a, b) => a.type < b.type ? -1 : 1);
      return {...x, types};
    });
  }

  isProd(): boolean {
    return this.istdService.prod;
  }

  newMethod(source: 'files' | 'database'): void {
    const istdRef = this.methodDialog.open(IstdEditComponent, {
      data: {title: 'Add first ISTD to create new method', source},
      width: '1024px',
      height: '90%',
    });

    istdRef.afterClosed()
    .pipe(
      tap(data => console.log(data)),
      filter(x => !(x === undefined || x === null || x.length < 1)),
      tap(fd => {
        const prev = this.fileMethodsSubject$.value;
        this.updateData(fd, prev);
      }),
    )
    .subscribe();
  }

  selectFile(): void {
    const importRef = this.fileImportDialog.open(FileImportDialogComponent, {
      width: '1024px',
      height: '90%',
    });
    importRef.afterClosed()
    .pipe(
      filter(x => !(x === undefined || x === null || x.length < 1)),
      tap(fd => {
        for (let tgt of fd.targets) {
          const prev = this.fileMethodsSubject$.value;
          this.updateData(tgt, prev);
        }
      }),
    ).subscribe();
  }

  showMethod(method: Method, source: string): void {
    localStorage.setItem('lastSelectedMethod', this.mp.transform(method));
    localStorage.setItem('lastSelectedMethodSource', source);
    this.router.navigate(['method'], {state: {method, source}, relativeTo: this.route.parent});
  }


  updateData(formdata: Istd, prev: Method[]): void {
    let newdata: Method[];

    const exists = prev?.find(m => {
      const fdAsMethod = [formdata.method, formdata.instrument, formdata.column, formdata.ion_mode].join(' | ');
      return this.mp.transform(m) === fdAsMethod;
    });

    if (exists) {
      exists.targets.push(formdata);
      newdata = this.typify([...prev?.filter(x => this.mp.transform(x) !== this.mp.transform(exists)), exists]);
    } else {
      const newMethod = {
        name: formdata.method,
        instrument: formdata.instrument,
        column: formdata.column,
        ionMode: formdata.ion_mode as IonMode,
        targets: [formdata]
      };
      newdata = this.typify([...prev, newMethod]);
    }

    localStorage.setItem('fileMethods', JSON.stringify(newdata));
    this.fileMethodsSubject$.next(newdata);
  }
}
