import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { TestComponent } from './test.component';
import { AggregationService } from './services/aggregation.service';

export const routes = [
  {path: '', component: TestComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AggregationService, {provide: 'env', useValue: environment}],
})
export class TestRoutingModule {
}
