import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TestRoutingModule } from './test-routing.module';
import { CisService } from '../../../projects/cis/src/lib/cis.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TestComponent } from './test.component';
import { AggregationService } from './services/aggregation.service';

@NgModule({
  declarations: [
    TestComponent,
  ],
  imports: [
    CommonModule,
    TestRoutingModule,
    ReactiveFormsModule,
  ],
  providers: [
    CisService,
    AggregationService,
    DecimalPipe,
  ]
})
export class TestModule {
}
