import { Component, inject, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Hub } from 'aws-amplify/utils';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  dialog = inject(MatDialog);
  authService = inject(AuthService);
  router = inject(Router);

  title = 'binbase-scheduler';

  navigating$: Observable<boolean> = of(false);

  constructor() {
    Hub.listen('auth', (data) => {
      this.authService.onAuthEvent(data);
    });
  }

  ngOnInit() {
    console.log('app init');
    this.navigating$ = this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      map(e => e instanceof NavigationStart)
    );
  }
}
