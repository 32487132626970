import { AfterViewInit, Component, inject, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  authService = inject(AuthService);

  private checkInterval: any;
  private timeoutHandle: any;
  private readonly intervalTime = 100; // Check every 100 milliseconds
  private readonly timeoutTime = 2000; // Timeout after 5000 milliseconds (5 seconds)

  navbarCollapsed: boolean;

  userIdSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userId$ = this.userIdSubject$.asObservable();

  constructor() {
    this.navbarCollapsed = true;
  }

  ngAfterViewInit(): void {
    this.waitForLocalStorageVariable();
  }

  /**
   * Periodically checks local storage for the presence of a 'user' variable.
   * Once the 'user' variable is found, the interval is cleared and its value
   * is emitted to the userIdSubject$ observable.
   *
   * @return {void} This method does not return a value.
   */
  private waitForLocalStorageVariable(): void {
    this.checkInterval = setInterval(() => {
      if (localStorage.getItem('user') != null) {
        clearInterval(this.checkInterval);
        clearTimeout(this.timeoutHandle);
        this.userIdSubject$.next(localStorage.getItem('user'));
      }
    }, this.intervalTime); // Check every 100 milliseconds

    this.timeoutHandle = setTimeout(() => {
      clearInterval(this.checkInterval);
      console.warn('Timeout: LocalStorage variable was not set within the expected time.');
      this.userIdSubject$.next('error getting username');
    }, this.timeoutTime);
  }

  logOut() {
    this.authService.onSignout();
  }

  ngOnDestroy() {
    this.checkInterval.unsubscribe();
    this.timeoutHandle.unsubscribe();
  }
}
