import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Amplify } from 'aws-amplify';
import { defaultStorage } from 'aws-amplify/utils';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';
import awsconfig from '../aws-exports';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, isDevMode, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { StasisModule } from 'stasis';
import { CisModule } from './cis/cis.module';

import { environment } from '../environments/environment';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { NavbarComponent } from './navbar/navbar.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { ModuleLoadErrorComponent } from './module-load-error/module-load-error.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NetworkInterceptor } from './standards-mgmt/utils/network.interceptor';
import { StandardsMgmtModule } from './standards-mgmt/standards-mgmt.module';
import { MethodPipe } from './standards-mgmt/utils/method.pipe';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TestModule } from './test/test.module';

Amplify.configure(awsconfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);


@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    NavbarComponent,
    FrontPageComponent,
    NotFoundComponent,
    ModuleLoadErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AmplifyAuthenticatorModule,
    AppRoutingModule,
    StasisModule.forRoot(environment),
    CisModule.forRoot(environment),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    NgbModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    StandardsMgmtModule,
    TestModule,

// NgRx related modules
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({maxAge: 10, logOnly: !isDevMode()}),
    EffectsModule.forRoot([]),
  ],
  providers: [
    DatePipe,
    MethodPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
