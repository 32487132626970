import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CisService {

  private cisURL: string;

  private URL: string;
  private apiKey: string;

  private methodsPath = 'libraries';

  constructor(
    private http: HttpClient,
    @Inject('env') private env,
  ) {
    this.URL = env.CIS_URL;
    this.cisURL = env.CIS_URL;
    this.apiKey = localStorage.getItem('x-api-key') ?? '';
    console.log('cis svc got apikey:', this.apiKey);
  }

  private buildRequestOptions(apiKey: string = this.apiKey) {
    return {
      headers: new HttpHeaders().append('x-api-key', this.apiKey).append('Content-Type', 'application/json')
    };
  }

  getMethods(): Observable<string[]> {
    return this.http.get<string[]>(`${this.URL}/${this.methodsPath}`, this.buildRequestOptions());
  }
}
