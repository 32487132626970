<amplify-authenticator variation="modal">
  <ng-template amplifySlot="header" class="login-header">
    <div class="login-header">
      <h1 class="align-self-center align-content-center text-center">Binview Login</h1>
    </div>
  </ng-template>

  <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
    <app-navbar></app-navbar>
    <div class="m-4">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</amplify-authenticator>
