export class JobMeta {
  logGroup?: string;
  logStream?: string;
  profile: string;
  timestamp: number;
  method: string;
  reason?: string;
  state: string;
  durations?: any;
  'past_states'?: string[];
  id: string;
  job: string;
  resource: string;
  selected?: boolean;
  distribution?: Map<[key: string], number>
}
