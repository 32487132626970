import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { Method } from '../models/Method';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Istd } from '../models/Istd';
import { TargetType } from '../models/TargetType';
import { IonMode } from '../models/IonMode';
import { MethodPipe } from '../utils/method.pipe';
import * as rp from '../shared/regexPatterns';

@Injectable({
  providedIn: 'root'
})
export class IstdService {
  prod = environment.production;

  private splashUrl = environment.SPLASH_URL;
  private cisUrl = environment.CIS_URL;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private mp: MethodPipe,
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': localStorage.getItem('x-api-key')
    });

    this.getAllIstds$()
    .pipe(
      catchError(err => {
        console.log('ERROR', err, '\nReturning empty array');
        return of([] as Istd[]);
      }),
    ).subscribe();

    console.log('getting methods');
    this.getAllMethods$()
    .pipe(
      catchError(err => {
        console.log('ERROR', err, '\nReturning empty array');
        return of([] as Method[]);
      }),
    ).subscribe();
  }

  private mSubject = new BehaviorSubject<Method[]>([]);
  methods$: Observable<Method[]> = this.mSubject.asObservable();

  private iSubject = new BehaviorSubject<Istd[]>([]);
  istds$: Observable<Istd[]> = this.iSubject.asObservable();

  getsingleistd() {
    return this.http.get<any>(`${this.cisUrl}/istd/175570`, {headers: this.headers});
  }

  getAllIstds$(): Observable<Istd[]> {
    return this.http.get<any[]>(`${this.cisUrl}/istd`)
    .pipe(
      map(istds => istds.map(i => {
        i.identifier = i.name;
        i.precursor_mass = i.pre_cursors_mass;
        delete i.name;
        delete i.pre_cursors_mass;
        const adductGroup = i.identifier?.match(rp.adductRegex);
        const inchikeyGroup = i.identifier?.match(rp.inchiKeyRegex);
        i.adduct = i.adduct ? i.adduct : adductGroup?.length > 1 ? adductGroup[1] : '';
        i.inchi_key = i.inchi_key ? i.inchi_key : inchikeyGroup?.length ? inchikeyGroup[1] : '';

        return {...i} as Istd;
      })),
      catchError(err => {
        console.log(err);
        return of([] as Istd[]);
      }),
      tap(istds => this.iSubject.next(istds)),
      shareReplay(),
    );
  }

  getAllMethods$(): Observable<Method[]> {
    let errors: string[] = [];

    return this.http.get<string[]>(`${this.cisUrl}/libraries`)
    .pipe(
      switchMap(methodNames => {
        const methodObservables: Observable<Method>[] = methodNames.map((method: string) => {
          const [name, instrument, column, im] = method.split(' | ');
          const ionMode = im as IonMode;
          return this.istds$
          .pipe(
            map(targets => {
              const filteredTargets = targets.filter(target => target.method === method);
              return {name, instrument, column, ionMode, targets: filteredTargets} as Method;
            }),
            take(2),
          );
        });

        return forkJoin(methodObservables);
      }),
      tap(methods => this.mSubject.next(methods)),
      catchError(error => {
        console.error(`Failed to fetch methods. Error: ${error}`);
        errors.push(`Failed to fetch methods. Error: ${error}`);
        return throwError(error);
      }),
      shareReplay(),
    );
  }

  loadIstdsForMethod(method: Method): Observable<Istd[]> {
    return this.istds$
    .pipe(
      map(istds => {
        if (method.targets?.length) {
          return method.targets;
        }
        return istds.filter(i => i.method === this.mp.transform(method));
      }),
    );
  }

  addIstd(istd: Istd): Observable<Istd> {
    const user = localStorage.getItem('user');    // to keep track of who made the latest modification
    const mode = istd.id ? 'update' : 'create';

    return this.query$(istd, mode === 'update')
    .pipe(
      tap(istd => {
        // console.log(istd);
        this.iSubject.next([...this.iSubject.value, istd]);
      }),
      catchError(error => {
        console.log(error);
        return of(null);
      }),
    );
  }

  query$(istd: Istd, editMode: boolean): Observable<Istd> {
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Access-Control-Allow-Credentials', 'true');
    this.headers.set('Access-Control-Allow-Headers', 'Content-Type,Access-Control-Allow-Origin');
    this.headers.set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE');

    if (editMode) {
      return this.http.put<Istd>(`${this.cisUrl}/istd/${istd.id}`, JSON.stringify(istd), {headers: this.headers});
    } else {
      return this.http.post<Istd>(`${this.cisUrl}/istd`, JSON.stringify(istd), {headers: this.headers});
    }
  }

  getSplash$(msms: string): Observable<string> {
    const jstr = this.convert(msms);

    return this.http.post<string>(`${this.splashUrl}/splash/it`, JSON.stringify(jstr),
      {headers: this.headers, responseType: 'text' as 'json'})
    .pipe(
      catchError(error => this.handleError(error)),
    );
  }

  protected convert(data: string): Spectrum {
    const type = 'MS';
    if (data) {
      const ions = data.split(/\s+/)
      .map(ion => {
        const d = ion.split(':');
        return {mass: parseFloat(d[0]), intensity: parseFloat(d[1])} as Ion;
      });
      return {ions, type} as Spectrum;
    } else {
      return {ions: [], type} as Spectrum;
    }
  }

  private handleError(error: HttpErrorResponse): Observable<string> {
    console.log(error);
    return of('');
  }


  convertJsonToIstd(data: any, method: string, instrument: string, column: string, ionMode: string | IonMode): Istd {
    const type: TargetType = data.target_type ? data.target_type :
      data.type ? data.type :
        data.isInternalStandard ? TargetType.istd :
          TargetType.manual;

    return {
      identifier: data.name,
      accurate_mass: data.accurateMass,
      pre_cursors_mass: data.accurateMass,
      pre_cursors_intensity: data.pre_cursors_intensity ? data.pre_cursors_intensity : -1,
      adduct: data.adduct,
      retention_index: data.retentionTimeUnit === 'minutes' ?
        data.retentionTime * 60 : data.retentionTime,
      inchi_key: data.inchikey,
      target_type: type,
      msms: data.msms,
      confirmed: data.confirmed,
      splash: data.splash,
      hidden: false,
      method,
      version: null,
      ion_mode: ionMode as IonMode,
      sample: null,
    } as Istd;
  }

}

export interface Ion {
  mass: number;
  intensity: number;
}

export interface Spectrum {
  ions: Ion[];
  type: string;
}
